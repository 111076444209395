import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCannabis,
	faHeart,
	faLongArrowAltRight,
	faRandom,
	faVest,
	faYinYang,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const services = [
	{
		icon: faHeart,
		title: "Background Removing",
		description:
			"To them sea. Give upon. Divide land called second lesser. Created dominion own unto form days created can't fifth doesn't.",
	},
	{
		icon: faCannabis,
		title: "Fun Video Creating",
		description:
			"Under is So wherein heaven meat great so. In man void. Multiply creepeth. Morning i, fruit gathering itself i make...",
	},
	{
		icon: faRandom,
		title: "Blog Writing",
		description:
			"Without Given bring there seasons our have second you're seasons waters he form, us forth, divided cattle. She'd..",
	},
	{
		icon: faYinYang,
		title: "Professional Editing",
		description:
			"Kind gathered saw them itself had very day may set herb created them. Meat together upon of creeping, god that.",
	},
	{
		icon: faVest,
		title: "Signature Analysis",
		description:
			"Created. Saw god wherein appear our subdue for earth subdue above every. Divided seas fowl grass sea good there moved..",
	},
	{
		icon: faRandom,
		title: "Branding Cloths",
		description:
			"Void bearing day forth set a whose. Second a darkness be also spirit own made darkness and image unto so..",
	},
];

const ServiceItem = ({ service }) => (
	<div className="bg-gray-100 dark:bg-slate-800 hover:shadow-lg dark:hover:shadow-lg hover:-translate-y-1 h-full p-6 transition duration-500">
		<div className="pt-6 p-4">
			<FontAwesomeIcon icon={service.icon} className="text-[40px]" />
		</div>
		<div className="p-4">
			<h5 className="text-lg font-semibold mb-4">{service.title}</h5>
			<p className="opacity-70 mb-6">{service.description}</p>
			<a
				href="#!"
				className="text-sm font-semibold hover:text-blue-600 duration-300"
			>
				LEARN MORE{" "}
				<FontAwesomeIcon icon={faLongArrowAltRight} className="ml-1" />
			</a>
		</div>
	</div>
);

ServiceItem.propTypes = {
	service: PropTypes.object.isRequired,
};

const Service = () => {
	return (
		<section className="py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white">
			<div className="container px-4">
				<div className="grid grid-cols-12">
					<div className="col-span-12 mb-12 text-center">
						<h3 className="text-3xl leading-none font-bold md:text-[45px]">
							Our Services
						</h3>
						<p className="text-lg opacity-80 mt-4 mb-2">
							Lorem ipsum dolor sit amet consectetur adipiscing elit.
						</p>
					</div>
				</div>

				<div className="grid grid-cols-12 gap-6">
					{services.map((service, i) => (
						<div className="col-span-12 md:col-span-6 xl:col-span-4" key={i}>
							<ServiceItem service={service} />
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Service;

