import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const routes = [
	{ name: "Home", href: "/", isActive: true },
	{ name: "Careers", href: "/careers", isActive: false },
	{ name: "FAQ's", href: "/faq", isActive: false },
	{ name: "Our Work", href: "/our-work", isActive: false },
	{ name: "Contact us", href: "/contact-us", isActive: false },
];

const NavMenu = ({ routes }) => (
	<>
		{routes.map((route, i) => (
			<li key={i}>
				<NavLink
					className={`px-4 ${
						route.isActive ? "opacity-100" : "opacity-50 hover:opacity-100"
					}`}
					to={route.href}
				>
					{route.name}
				</NavLink>
			</li>
		))}
	</>
);

NavMenu.propTypes = {
	routes: PropTypes.array.isRequired,
};

const AuthNavMenu = () => (
	<>
		<li>
			<button className="border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white py-1.5 px-4 rounded">
				Sign In
			</button>
		</li>
		<li>
			<button className="border border-blue-600 bg-blue-600 text-white hover:bg-opacity-90 py-1.5 px-4 rounded">
				Sign Up
			</button>
		</li>
	</>
);

const Navigation = () => {
	return (
		<div className="py-6 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white relative">
			<nav>
				<div className="container px-4">
					<div className="flex justify-between items-center">
						<NavLink className="font-black text-3xl" to="/">
							{" "}
							Binary Boulevard{" "}
						</NavLink>
						<button
							className="block lg:hidden cursor-pointer h-10 z-20"
							type="button"
							id="hamburger"
						>
							<div className="h-0.5 w-7 bg-black dark:bg-white -translate-y-2"></div>
							<div className="h-0.5 w-7 bg-black dark:bg-white"></div>
							<div className="h-0.5 w-7 bg-black dark:bg-white translate-y-2"></div>
						</button>
						<ul
							className="flex flex-col lg:flex-row justify-center items-center text-3xl gap-6 lg:text-base lg:gap-2 absolute h-screen w-screen top-0 left-full lg:left-0 lg:relative lg:h-auto lg:w-auto bg-white dark:bg-[#0b1727] lg:bg-transparent"
							id="navbar"
						>
							<NavMenu routes={routes} />
							<AuthNavMenu />
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Navigation;

