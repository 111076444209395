import React, {Fragment} from 'react';
import HeroHeader from "../components/HeroHeader";
import Service from "../components/Service";
import HowItWorks from "../components/HowItWorks";
import CallToAction from "../components/CallToAction";
import ClientLogo from "../components/ClientLogo";
import Testimonial from "../components/Testimonial";


const Index = () => {
    return (
        <Fragment>
			<HeroHeader />
			<Service />
			<HowItWorks />
			<CallToAction />
			<ClientLogo />
			<Testimonial />
        </Fragment>
    );
}

export default Index;
